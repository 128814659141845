<template>
  <b-row align-h="center">
    <b-col cols="6">
      <b-card>
        <div
          v-if="isLoading"
          class="text-center"
        >
          <b-spinner />
          <p>Carregando...</p>
        </div>
        <validation-observer
          v-else
          ref="rulesProfile"
        >
          <div class="mb-1 text-center">
            <avatar-input
              ref="avatar"
              :name-photo="user.name"
              :user-id="user.id"
              :avatar-image="userEditing.avatar"
              :method-to-save="userService.saveAvatar"
              :method-to-rotate90="userService.rotateAvatar90"
              :method-to-rotate270="userService.rotateAvatar270"
              :method-to-remove="userService.removeAvatar"
              @preview="previewAvatar"
            />
          </div>
          <b-form-group
            label="E-mail"
            label-for="profile_email"
          >
            <b-form-input
              id="profile_email"
              :value="user.email"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Alterar Senha"
            label-for="profile_password"
          >
            <validation-provider
              #default="{ errors }"
              rules="min:3"
            >
              <b-form-input
                id="profile_password"
                v-model="userEditing.password"
                type="password"
                placeholder="deixar em branco para não alterar a senha"
                :disabled="isSubmiting"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Nome"
            label-for="profile_name"
          >
            <b-form-input
              id="profile_name"
              :value="user.name"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Cargo"
            label-for="profile_position"
          >
            <b-form-input
              id="profile_position"
              :value="user.position_in_company"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Área"
            label-for="profile_role"
          >
            <b-form-input
              id="profile_role"
              :value="readableRole"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Telefone"
            label-for="profile_cell_phone"
          >
            <b-form-input
              id="profile_cell_phone"
              :value="user.cell_phone"
              plaintext
            />
          </b-form-group>
          <b-form-group
            label="Sobre"
            label-for="presentation"
          >
            <b-form-textarea
              id="presentation"
              v-model="userEditing.manager_profile.presentation"
            />
          </b-form-group>
          <b-form-group
            label="Gostos e Habilidades (um por linha)"
            label-for="interests"
          >
            <b-form-textarea
              id="interests"
              v-model="interests"
            />
          </b-form-group>
          <b-form-group
            label="Link do perfil"
            label-for="slug"
          >
            <b-input-group>
              <b-form-input
                id="slug"
                :value="user.slug"
                readonly
              />
              <b-input-group-append>
                <b-button @click="copyProfileUrl">
                  <feather-icon
                    icon="LinkIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-row
            align-h="end"
            class="mt-3"
          >
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                variant="primary"
                type="submit"
                :disabled="isSubmiting"
                class="ml-auto"
                @click.prevent="saveUserRegistration()"
              >
                Salvar
                <b-spinner
                  v-if="isSubmiting"
                  small
                />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BCard,
} from 'bootstrap-vue'
import LocalStorageService from '@/services/localStorageService'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from '@/services/userService'
import {
  consultantAreas,
} from '@/utils/options'
import LoginService from '@/services/loginService'
import AvatarInput from '../components/AvatarInput.vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BCard,

    AvatarInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const user = LocalStorageService.getArray(LocalStorageService.userData)

    if (!user.manager_profile) {
      user.manager_profile = {}
    }

    return {
      consultantAreas,
      userService: new UserService(),

      user,
      userEditing: {
        ...user,
      },

      isSubmiting: false,
      isLoading: true,

      interests: user?.manager_profile?.interests?.join('\n'),
    }
  },
  computed: {
    readableRole() {
      return this.consultantAreas.find(role => role.value === this.user.permission_type)?.text
    },

    pristine() {
      return JSON.stringify(this.userEditing) === JSON.stringify(this.user)
    },
  },
  mounted() {
    this.isLoading = false
  },
  created() {
    // reload abilities
    const loginService = new LoginService()
    this.user.ability = loginService.getAbility(this.user)
    LocalStorageService.setArray(LocalStorageService.userData, this.user)
  },
  methods: {
    previewAvatar(avatar) {
      this.userEditing.avatar = avatar

      LocalStorageService.setArray(LocalStorageService.userData, {
        ...LocalStorageService.getArray(LocalStorageService.userData),
        ...{
          avatar,
        },
      })

      this.$root.$emit('cms::avatar-changed')
    },

    saveUserRegistration() {
      return this.$refs.rulesProfile.validate().then(success => {
        if (!success) {
          this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
          return
        }

        const interests = this.interests.split('\n').filter(f => f.trim() !== '').map(m => {
          const interest = m.trim()
          return interest.charAt(0).toUpperCase() + interest.slice(1)
        })

        this.interests = interests.join('\n')

        const dataToUpdate = {
          consultant: 'yes',
          id: this.userEditing.id,
          password: this.userEditing.password,
          manager_profile: {
            presentation: this.userEditing.manager_profile.presentation,
            interests,
          },
        }

        this.isSubmiting = true
        this.userService.updateRegistration(this.user.id, dataToUpdate).then(response => {
          const { user } = response.data.data

          this.userEditing.password = ''
          this.$refs.rulesProfile.reset()

          LocalStorageService.setArray(LocalStorageService.userData, user)
          this.user = user
          this.userEditing = { ...user }

          this.isSubmiting = false
          this.toast('success', 'Perfil alterado!')
        }).catch(error => {
          this.isSubmiting = false
          let msg = error.response.data.message && error.response.data.message.search('was invalid') === -1 ? error.response.data.message : ''
          if (Object.values(error.response.data.errors).length > 0) {
            msg = msg.length > 0 ? `${msg} - ` : msg
            msg = `${msg} ${Object.values(error.response.data.errors).join('')}`
          }

          this.swall('error', 'Ops - Algo deu errado', msg)
        })
      })
    },

    copyProfileUrl() {
      const el = document.createElement('textarea')
      el.value = this.user.slug
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Link copiado com sucesso',
          icon: 'LinkIcon',
          variant: 'success',
        },
      })

      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }

      return true
    },

    toast(type, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: type === 'success' ? 'EditIcon' : 'BellIcon',
          variant: type,
        },
      })
    },

    swall(type, title, text) {
      this.$swal({
        title,
        text,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
